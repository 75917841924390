'use client'

/* eslint-disable arrow-body-style */

import React from 'react'
import Image from 'next/image'
import assetLoader from '@utils/internalImageLoader'
import styles from './globalFooter.module.scss'

const socialFooterLinks = () => {
  return (
    <div className={styles.socialMediaIcons}>
      <a target="_blank" href="https://www.instagram.com/ackoindia/" rel="noopener">
        <Image
          src={`${assetLoader()}/images/Instagram_fill.svg`}
          alt="Instagram profile"
          width={32}
          height={32}
          loading="lazy"
        />
      </a>
      <a target="_blank" href="https://www.linkedin.com/company/acko/" rel="noopener">
        <Image
          src={`${assetLoader()}/images/LinkedIn_idle.svg`}
          alt="Linkedin profile"
          width={32}
          height={32}
          loading="lazy"
        />
      </a>
      <a target="_blank" href="https://twitter.com/AckoIndia" rel="noopener">
        <Image
          src={`${assetLoader()}/images/Twitter_idle.svg`}
          alt="Twitter profile"
          width={32}
          height={32}
          loading="lazy"
        />
      </a>

      <a target="_blank" href="https://www.youtube.com/channel/UCn1gqDU9TqbR8u6_10S0RJA" rel="noopener">
        <Image
          src={`${assetLoader()}/images/YouTube_idle.svg`}
          alt="Carousel Circle profile"
          width={32}
          height={32}
          loading="lazy"
        />
      </a>
      <a target="_blank" href="https://www.facebook.com/ackoindia/" rel="noopener">
        <Image
          src={`${assetLoader()}/images/Facebook_idle.svg`}
          alt="Carousel Circle profile"
          width={32}
          height={32}
          loading="lazy"
        />
      </a>
    </div>
  )
}

export default socialFooterLinks
