import { trackEvent } from '@acko-tech/building-blocks.ui.acko-analytics'
import { TAP_HEADER_MYACCOUNT } from './constants'

export const handleRedirection = async (url: string | undefined, handleOnClick: Function | undefined, item?: string, shouldRedirect?: boolean) => {
  await trackEvent(TAP_HEADER_MYACCOUNT, { header_name: item })
  const newUrl = url || '/discover/profile'
  await handleOnClick?.()
  shouldRedirect && typeof window !== 'undefined' && (window.location.href = newUrl)
}

export const getFilteredName = userData => {
  const userName = userData?.name?.split(' ')[0]
  if (userName?.length > 23) {
    return `${userName?.substring(0, 23)}...`
  }
  return userName
}

export const isProfilePagePath = () => {
  if (typeof window !== 'undefined') {
    const urlWithoutParams = window.location.href.split('?')[0]
    return urlWithoutParams?.includes('profile')
  }
  return false
}

export const isMyaccountPagePath = () => {
  if (typeof window !== 'undefined') {
    const urlWithoutParams = window.location.href.split('?')[0]
    return urlWithoutParams?.includes('myaccount')
  }
  return false
}
