/* eslint-disable react/no-danger */
import { useEffect } from 'react'
import { getCommonEventProperties, isDesktop, trackEvent } from '@acko-tech/building-blocks.ui.acko-analytics'
import { type Link } from './interfaces/Link'
import styles from './globalHeader.module.scss'
import { TAP_HEADER_TABS, APP_DOWNLOAD_LINK, TAP_BTN_ACKO_APP } from './constants'
import { largeIcons } from './largeIcons'

const Separator = () => <div className={styles.separator} />

export const Links = (props: {
  links: Link[]
  show: boolean // for deskop
  isShowingLinks?: boolean // for mobile
  isLoggedIn: boolean
  setIsShowingLinks?: Function
  category?: string
  product?: string
  pageType?: any
  currentlyActiveSection?: any
  largeHeader?: boolean
}) => {
  const {
    links,
    show,
    isShowingLinks,
    category,
    product,
    setIsShowingLinks,
    pageType,
    largeHeader,
    currentlyActiveSection,
    isLoggedIn,
  } = props
  useEffect(() => {
    if (isDesktop() && setIsShowingLinks) setIsShowingLinks(show)
  }, [show])
  return (
    <div
      className={`${styles.links} ${
        (isDesktop() && show) || (!isDesktop() && isShowingLinks && show) ? styles.showLink : ''
      } ${largeHeader ? styles.largeHeader : ''}`}>
      {links?.map(link =>
        !((isLoggedIn && link.forLoggedIn) || (!isLoggedIn && link.forLoggedOut)) ? null : link.title === '.' ? (
          <Separator />
        ) : (
          <a
            className={`${link.href ? styles.regularLink : styles.noLink} ${link.blue ? styles.blue : ''}`}
            href={link.href || '#'}
            target="_blank"
            onClick={e => {
              e.stopPropagation()
              void trackEvent(TAP_HEADER_TABS, {
                tab_names: [category],
                product: product || '',
                header_name: link.title || '',
                redirection_url: link.href || '',
                ...getCommonEventProperties(pageType),
              })
            }}>
            {link.title}
          </a>
        )
      )}

      {show || currentlyActiveSection !== -1 ? (
        <span
          className={styles.bottomImage}
          onClick={() => {
            void trackEvent(TAP_BTN_ACKO_APP, {
              ...getCommonEventProperties(pageType),
            })
            window.open(APP_DOWNLOAD_LINK, '_blank')
          }}
          dangerouslySetInnerHTML={{
            __html: `<img src="${largeIcons.appBanner}" class=${styles.bottomImage} alt='Global header App Banner image' />`,
          }}
        />
      ) : null}
    </div>
  )
}
