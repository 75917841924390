/* eslint-disable @typescript-eslint/no-misused-promises */

import React, { useEffect } from 'react'

import Image from 'next/image'
import styles from './profieModalStyles.module.scss'
import { LastVisitedApiInstance } from './api'
import { DateFormater } from './utils'
import { AvatarWhiteBackground } from './modal-assets/modal-assets-icons'
import { icons } from '../icons'
import { getFilteredName, handleRedirection } from '../helper'

const Modal: React.FC<ModalProps> = ({
  handleIconClick,
  handleLogout,
  userData,
  largeHeader,
  showModal,
  avatarRef,
  handleMyPoliciesClicked,
}) => {
  const NavbarContainer = [
    {
      title: 'My policies',
      url: '/myaccount',
      onClick: handleMyPoliciesClicked,
      iconUrl: <Image src={icons.myPolicies} alt="Global header My policies icon" width={32} height={32} />,
      shouldRedirect: true
    },
    {
      title: 'Logout',
      url: '/',
      onClick: handleLogout,
      iconUrl: <Image src={icons.logoutIcon} alt="Global header Logout icon icon" width={32} height={32} />,
      hideOnMobile: true,
      shouldRedirect: false
    },
  ]

  const [lastVisited, setLastVisited] = React.useState('')
  const callLastVisitedAPI = () => {
    LastVisitedApiInstance.get('/analytics/v1/visit')
      .then(result => {
        if (result.status === 200 && result?.data?.last_visited_time) {
          const formated = result?.data?.last_visited_time.replace(' ', 'T')
          const formatedTimeZone = formated.concat('.000+05:30')
          setLastVisited(DateFormater(formatedTimeZone))
        }
      })
      .catch(err => console.log('Last visited api error', err))
  }
  console.log(lastVisited)
  useEffect(() => {
    /* Disabled last visited since backend changes are not ready */
    callLastVisitedAPI()
    // Desktop Modal will close when clicked on these elements
    const closeModalOnClickIds = ['myAccountParent', 'internetListing', 'policyDetails']
    closeModalOnClickIds.map(id => {
      document.getElementById(id)?.addEventListener('click', handleOnClickElement)
    })
    return () => {
      closeModalOnClickIds.map(id => {
        document.getElementById(id)?.removeEventListener('click', handleOnClickElement)
      })
    }
  }, [])

  const handleOnClickElement = () => {
    handleIconClick(false)
  }

  return (
    <div
      ref={avatarRef}
      className={`${styles.rootContainer} ${largeHeader ? styles.largeHeader : ''} ${
        showModal ? styles.showModal : styles.hideModal
      }`}>
      <div className={`${styles.modalContainer}`}>
        <div className={`${styles.modalWrapper} ${largeHeader ? styles.largeHeader : ''}`}>
          <div className={styles.modalBox}>
            <div className={styles.profileSection}>
              <div className={`${styles.flex} ${styles.alignItemsCenter}`}>
                <div className={styles.avatarContainer}>
                  <span className={styles.styledImageContainer}>
                    <Image src={AvatarWhiteBackground} alt="Avatar white background global header" width={40} height={40} />
                  </span>
                </div>
                <div className={styles.basicInfo}>
                  <div className={styles.name}>{getFilteredName(userData)}</div>
                  <div
                    className={styles.profileLink}
                    onClick={() => handleRedirection(undefined, undefined, 'View Profile', true)}>
                    View Profile
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.divider}`} />
            <div className={`${styles.flex} ${styles.flexDirectionColumn}`}>
              {NavbarContainer.map(
                (item, index) => (
                  <div
                    onClick={() => handleRedirection(item.url, item.onClick, item.title, item.shouldRedirect)}
                    key={index}
                    style={{ textDecoration: 'none' }}
                    className={`${styles.navItemContainerWrapper} ${item.hideOnMobile ? styles.hideOnMobile : ''}`}>
                    <div className={`${styles.navItemContainer}`}>
                      {item.iconUrl}
                      <div className={styles.navTitle}>{item.title}</div>
                    </div>
                    <div
                      className={`${styles.divider} ${item.hideOnMobile ? styles.hideOnMobile : ''} ${
                        styles.mobileOnly
                      }`}
                    />
                  </div>
                )
                // )
              )}
              <div className={`${styles.divider} ${styles.hideOnMobile} ${styles.mobileOnly}`} />
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  )
}

export default Modal

interface UserApiResponse {
  addresses: string[]
  amazon_prime: boolean
  date_of_birth: null | string
  email: string
  name: string
  phone: string
  whatsapp_optin: boolean
}

interface ModalProps {
  handleIconClick: (boolean) => void
  userData?: UserApiResponse
  largeHeader?: boolean
  handleLogout?: () => void
  showModal: boolean
  avatarRef: any
  handleMyPoliciesClicked?: () => void
}
