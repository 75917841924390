'use client'

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-body-style */

import React, { useLayoutEffect, useState, useRef } from 'react'
import Image from 'next/image'
import assetLoader from '@utils/internalImageLoader'
import styles from './btf.module.scss'
import styles2 from './globalFooter.module.scss'

const GlobalFooterItemDropdown = ({
  classList,
  FooterItem,
  itemNumber,
  footerRowIndex,
  aboveTheFold = false,
  isMobileDevice,
  isHomeWrapper,
}) => {
  const [toggleDropdownIcon, setToggleDropdownIcon] = useState<boolean>(false)
  const abc = useRef(false)

  function applyClassToggle(ulElement, isActive, domItem) {
    if (abc.current) {
      if (isActive) {
        ulElement.classList.remove(domItem.activeClass)
        ulElement.classList.add(domItem.inactiveClass)
      } else {
        ulElement.classList.remove(domItem.inactiveClass)
        ulElement.classList.add(domItem.activeClass)
      }
    } else {
      ulElement.classList.remove(domItem.activeClass)
      ulElement.classList.add(domItem.inactiveClass)
    }
  }

  useLayoutEffect(() => {
    const domItem = aboveTheFold
      ? {
          parentElement: 'ackoMainContentLinks',
          subElement: '#ackoMainContentLinksList',
          activeClass: styles2.active,
          inactiveClass: styles2.inactive,
        }
      : {
          parentElement: `footerRow${footerRowIndex}`,
          subElement: '#footerAckoContentbtf',
          activeClass: styles.active,
          inactiveClass: styles.inactive,
        }

    const footerRowParentElement: any = document.getElementById(domItem.parentElement)
    const nestedULElements = footerRowParentElement.querySelectorAll(domItem.subElement)

    nestedULElements.forEach((ulElement, index) => {
      const ulId = ulElement.id

      if (ulId === 'ackoMainContentLinksList') {
        if (itemNumber === index) {
          const isActive = ulElement.classList.contains(domItem.activeClass)
          applyClassToggle(ulElement, isActive, domItem)
        }
      }

      if (!isMobileDevice && ulId === 'footerAckoContentbtf') {
        const isActive = ulElement.classList.contains(domItem.activeClass)
        applyClassToggle(ulElement, isActive, domItem)
      }

      if (isMobileDevice && ulId === 'footerAckoContentbtf') {
        if (itemNumber === index) {
          const isActive = ulElement.classList.contains(domItem.activeClass)
          applyClassToggle(ulElement, isActive, domItem)
        }
      }
    })

    const container = document.getElementById(`globalFooterRowContainer${footerRowIndex}`)
    if (container) {
      if (toggleDropdownIcon) {
        container.classList.add(styles.footerTitleArrowClosed)
        container.classList.remove(styles.footerTitleArrowUp)
      } else {
        container.classList.add(styles.footerTitleArrowUp)
        container.classList.remove(styles.footerTitleArrowClosed)
      }
    }
  }, [toggleDropdownIcon])

  return (
    <p
      className={classList === 'atf' ? styles2.footerItemTitle : styles.footerAckoLinksHeading}
      onClick={() => {
        if (isMobileDevice) {
          abc.current = true
          setToggleDropdownIcon(!toggleDropdownIcon)
        }
      }}>
      {FooterItem.title}{' '}
      <span className={toggleDropdownIcon ? styles.footerTitleArrownClosedMobile : styles.footerTitleArrowUpMobile}>
        <Image
          src={
            classList === 'atf'
              ? `${assetLoader()}/images/${isHomeWrapper ? 'footer-arrow-down-white.svg' : 'footer-arrow-down.svg'}`
              : `${assetLoader()}/images/footer-arrow-white-down.svg`
          }
          alt="Footer Arrow White Icon"
          height={24}
          width={24}
          loading="lazy"
        />
      </span>
    </p>
  )
}

export default GlobalFooterItemDropdown
