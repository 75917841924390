import { generateLogoutUrl } from '@utils/loginUtil'

export const logoutUser = async ({ userId, refreshToken, env }) => {
  try {
    const response = await fetch(generateLogoutUrl(env), {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `Bearer ${userId}` },
      body: new URLSearchParams({
        refresh_token: refreshToken,
      }).toString(),
    })

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Logout failed:', error)
    throw error
  }
}
