export const largeIcons = {
    renewalBottomImage: 'https://acko-seo-prod.ackoassets.com/_next_static/images/renewal-bottom-image.svg',
    productBottomImage: 'https://acko-seo-prod.ackoassets.com/_next_static/images/product-bottom-image.svg',
    claimBottomImage: 'https://acko-seo-prod.ackoassets.com/_next_static/images/claim-bottom-image-new.svg',
    avatarGreyBackground: 'https://acko-seo-prod.ackoassets.com/_next_static/images/avatar-grey-background.svg',
    avatarWhiteBackground: 'https://acko-seo-prod.ackoassets.com/_next_static/images/avatar-white-background.svg',
    appBanner: 'https://acko-seo-prod.ackoassets.com/_next_static/images/app-banner.svg',
    claimsIcon: 'https://acko-seo-prod.ackoassets.com/_next_static/images/claims-icon.svg',
    titleImage: 'https://acko-seo-prod.ackoassets.com/_next_static/images/title-image.svg',
    titleImageDark: 'https://acko-seo-prod.ackoassets.com/_next_static/images/title-image-dark.svg',
    productMainImage: 'https://acko-seo-prod.ackoassets.com/_next_static/images/product-category-main-icon.svg',
    renewPolicyMainImage: 'https://acko-seo-prod.ackoassets.com/_next_static/images/renew-catogory-main-icon.svg',
    resourcesMainImage: 'https://acko-seo-prod.ackoassets.com/_next_static/images/resources-category-main-icon.svg',
}
