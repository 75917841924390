/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable no-cond-assign */

'use client'

export const getFullFormattedDate = (timestamp, locale = 'en-IN') => {
  if (!timestamp) return ''
  const date = new Date(timestamp)
  const [day, month, year] = date
    .toLocaleDateString(locale, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
    .split(' ')

  const timeString = date
    .toLocaleTimeString(locale, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
    .toLocaleUpperCase()

  const timeZone = 'IST'

  return `${day} ${month}, ${year}, ${timeString} ${timeZone}`
}

export const range = (start, end, step = 1) => {
  if (end === undefined) {
    end = start
    start = 0
  }

  if (step === 0) {
    throw new Error('Step cannot be zero.')
  }

  const rangeArray: any = []
  const increasing = step > 0

  if (increasing) {
    for (let i = start; i < end; i += step) {
      rangeArray.push(i)
    }
  } else {
    for (let i = start; i > end; i += step) {
      rangeArray.push(i)
    }
  }

  return rangeArray
}

export function generateId(id) {
  if (typeof id === 'string') {
    return id
      .replace(/[^a-z0-9\s]/gi, '')
      .replace(/[^a-z0-9]/gi, '-')
      .replace(/[-]+$/gi, '')
      .toLowerCase()
  }
}

export function getTableOfContentsFromPost() {
  const TableOfContents: any = []
  return function getTableOfContentsFromPost(post) {
    for (const i in post?.attributes?.contentAfterToc) {
      const content = post?.attributes?.contentAfterToc?.[i]?.Content

      if (typeof content === 'string') {
        // Regular expression to match h2 and h3 tags
        const headingRegex = /<(h2|h3)([^>]*)>(.*?)<\/\1>/gi

        let match
        while ((match = headingRegex.exec(content)) !== null) {
          const tagName = match[1].toLowerCase() // 'h2' or 'h3'
          const innerHTML = match[3] // Content inside the heading tag

          // Remove any nested HTML tags within the heading text
          const textContent = innerHTML.replace(/<[^>]*>/g, '').trim()

          if (tagName === 'h2') {
            // Add a new h2 heading to the Table of Contents
            TableOfContents.push({
              text: textContent,
              children: [],
            })
          } else if (tagName === 'h3') {
            // Add h3 heading as a child of the last h2 heading
            if (TableOfContents.length > 0) {
              TableOfContents[TableOfContents.length - 1].children.push({
                text: textContent,
              })
            }
          }
        }
      }
    }
    return TableOfContents
  }
}

export function domNodeToHtml(node, isParentNode = false) {
  if (node.type === 'text') {
    return node.data || ''
  }

  const tagName = node.name || 'div'
  const attributes = node.attribs || {}
  const attributeString = Object.keys(attributes)
    .map(key => `${key}="${attributes[key]}"`)
    .join(' ')

  const children = node.children || []
  const childrenHtml = children.map(child => domNodeToHtml(child)).join('')

  return isParentNode
    ? childrenHtml
    : `<${tagName}${attributeString ? ` ${attributeString}` : ''}>${childrenHtml}</${tagName}>`
}
