export const TAP_BTN_SIGNUPNOW = 'tap_btn_signupnow'
export const TAP_HEADER_LOGIN_CTA = 'tap_header_login_cta'
export const SIGNUP_PATH = '/sign-up?next=%2Fmyaccount%3Fsource%3Dfree-sign-up'
export const TAP_HEADER_MYACCOUNT = 'tap_header_myaccount'
export const TAP_BTN_HEADER_HAMBURGER = 'tap_btn_header_hamburger'
export const TAP_HEADER_TABS = 'tap_header_tabs'
export const APP_DOWNLOAD_LINK = 'https://ackoapp.onelink.me/TnnH/5pydco52'
export const TAP_BTN_ACKO_APP = 'tap_btn_acko_app'
export const OLD_LOGIN_PATH = '/login?next=%2Fmyaccount'
export const KEY_USER_ID = 'user_id'
export const KEY_REFRESH_TOKEN = 'refresh_token'
