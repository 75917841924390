'use client'

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-body-style */

import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import assetLoader from '@utils/internalImageLoader'
import styles from './btf.module.scss'

const GlobalFooterDropdown = ({ row, isMobileDevice }) => {
  const [toggleDropdownIcon, setToggleDropdownIcon] = useState<boolean>(false)

  useEffect(() => {
    const footerRowParentElement: any = document.getElementById(`footerRow${row}`)
    const nestedULElements = footerRowParentElement.querySelectorAll('#footerAckoContentbtf')

    const handleClick = () => {
      if (!isMobileDevice) {
        setToggleDropdownIcon(!toggleDropdownIcon)

        nestedULElements.forEach(ulElement => {
          const ulId = ulElement.id

          if (ulId === 'footerAckoContentbtf') {
            const isActive = ulElement.classList.contains(styles.active)
            if (isActive) {
              ulElement.classList.remove(styles.active)
              ulElement.classList.add(styles.inactive)
            } else {
              ulElement.classList.remove(styles.inactive)
              ulElement.classList.add(styles.active)
            }
          }
        })
      }
    }

    if (footerRowParentElement) {
      footerRowParentElement.addEventListener('click', handleClick)
    }

    return () => {
      if (footerRowParentElement) {
        footerRowParentElement.removeEventListener('click', handleClick)
      }
    }
  }, [toggleDropdownIcon])

  return (
    <div
      className={toggleDropdownIcon ? styles.footerTitleArrowClosed : styles.footerTitleArrowUp}
      id={`globalFooterRowContainer${row}`}>
      <Image
        src={`${assetLoader()}/images/footer-arrow-white-down.svg`}
        alt="Footer Arrow White Icon"
        height={24}
        width={24}
        loading="lazy"
      />
    </div>
  )
}

export default GlobalFooterDropdown
