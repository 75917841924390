/* eslint-disable react/destructuring-assignment */
import styles from './button.module.scss'

interface IHeader {
  isPrimary?: boolean
  name: string
  onClick: Function
}

 const Button = (props: IHeader) => (
  // eslint-disable-next-line react/button-has-type
  <button
    onClick={() => props.onClick()}
    className={`${styles.button} ${props.isPrimary ? styles.primaryButton : styles.secondaryButton}`}>
    {props.name}
  </button>
)
export default Button
