/* eslint-disable react/no-danger */

'use client'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { trackEvent, getCommonEventProperties, isDesktop } from '@acko-tech/building-blocks.ui.acko-analytics'
import Image from 'next/image'
import assetLoader from '@utils/internalImageLoader'
import { HEADER_THEME } from '@styles/theme'
import { logoutUser } from '@services/loginService'
import { useEnvVariablesClientConfig } from '@context/EnvClientContext/EnvVariablesClientContext'
import Cookies from 'js-cookie'
import styles from './globalHeader.module.scss'
import Modal from './profile-modal/profile-modal'
import {
  KEY_REFRESH_TOKEN,
  KEY_USER_ID,
  SIGNUP_PATH,
  TAP_BTN_HEADER_HAMBURGER,
  TAP_BTN_SIGNUPNOW,
  TAP_HEADER_LOGIN_CTA,
  TAP_HEADER_MYACCOUNT,
} from './constants'
import { icons } from './icons'
import Button from './Button/Button'
import type IHeader from './interfaces/IHeader'
import { Categories } from './Categories'
import { ToggleIcon } from './ToggleIcon'
import { largeIcons } from './largeIcons'

interface IHeaderButton {
  setIsHeaderVisible: Function
  isHeaderVisible: boolean
  onClose: Function
  pageType?: any
  darkMobile: boolean
  isHamburgerVisible: boolean
  theme: HEADER_THEME
}
interface IFAQButton {
  faqLink: string
  showLoginStatus: boolean
  faqIcon: string
  isMobile?: boolean
}
const ProfileModal = ({
  avatarClick,
  userData,
  avatarRef,
  largeHeader,
  showProfileModal,
  isHeaderVisible = false,
  handleMyPoliciesClicked,
  handleLogout,
}) => (
  <Modal
    handleIconClick={avatarClick}
    userData={userData}
    showModal={showProfileModal || isHeaderVisible}
    avatarRef={avatarRef}
    handleLogout={handleLogout}
    largeHeader={largeHeader}
    handleMyPoliciesClicked={handleMyPoliciesClicked}
  />
)

const FAQButton = (props: IFAQButton) => {
  const { faqLink, faqIcon, showLoginStatus, isMobile } = props
  if (!faqLink || faqLink.length === 0) {
    return null
  }
  return (
    <div className={`${!showLoginStatus ? styles.faqSection : ''}`}>
      <div
        className={`${styles.buttonWrapper} ${!isMobile ? styles.forDesktop : styles.forMobile}`}
        onClick={() => window.open(faqLink, '_blank')}>
        <div
          className={`${styles.faqWrapper} ${!showLoginStatus ? styles.isSem : ''}  ${
            !isMobile ? styles.forDesktop : styles.forMobile
          }`}>
          <Image width={20} height={20} src={faqIcon} alt="FAQs section" />
          <span className={styles.faqText}>FAQs</span>
        </div>
      </div>
    </div>
  )
}
const HeaderButton = (props: IHeaderButton & IFAQButton) => {
  const {
    setIsHeaderVisible,
    isHeaderVisible,
    onClose,
    pageType,
    darkMobile,
    isHamburgerVisible,
    faqLink,
    faqIcon,
    showLoginStatus,
    isMobile = true,
    theme = HEADER_THEME.LIGHT,
  } = props
  const hamburgerClicked = e => {
    e.stopPropagation()
    e.preventDefault()
    if (isHeaderVisible) onClose()
    else {
      void trackEvent(TAP_BTN_HEADER_HAMBURGER, {
        ...getCommonEventProperties(pageType),
      })
    }
    setIsHeaderVisible((v: boolean) => !v)
  }
  return (
    <>
      <FAQButton faqLink={faqLink} faqIcon={faqIcon} showLoginStatus={showLoginStatus} isMobile={isMobile} />
      {isHamburgerVisible && (
        <div className={`${styles.headerButton} ${isHeaderVisible ? styles.buttonCross : ''} ${styles[theme]}`}>
          <button type="button" onClick={e => hamburgerClicked(e)} className={styles.headerButtonInput} />
          <div className={`${styles.buttonVisuals} ${darkMobile ? styles.darkMobile : ''} ${styles[theme]}`} />
        </div>
      )}
    </>
  )
}

const BackComponent = ({ white }) => (
  <span>
    {/* <LeftChevron stroke={white ? 'white' : ''} /> */}
    {icons.leftChevron({ stroke: white ? 'white' : '' })}
    <span className={white ? styles.backButtonWhite : styles.backButton}>Back</span>
  </span>
)

const GlobalHeader = (props: IHeader) => {
  useEffect(() => {
    function handleResize() {
      isDesktop() && setIsHeaderVisible(false)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [isHeaderVisible, setIsHeaderVisible] = useState(false)
  const [currentlyActiveSection, setCurrentlyActiveSection] = useState(-1)
  const [currentlySelectedType, setCurrentlySelectedType] = useState({
    type: '',
    typeIndex: -1,
  })

  const [isShowingLinks, setIsShowingLinks] = useState(false)
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false)
  const config = useEnvVariablesClientConfig()
  const {
    schema,
    darkDesktop = false,
    darkMobile = false,
    isLoggedIn,
    userData,
    largeHeader,
    pageType,
    theme = HEADER_THEME.DARK,
    isHomeWrapper,
    loginBaseUrl = '',
  } = props

  const AvatarClick = () => {
    void trackEvent(TAP_HEADER_MYACCOUNT, {
      ...getCommonEventProperties(pageType),
    }) // tap_header_profile_icon profile_icon_clicked
    setShowProfileModal(!showProfileModal)
  }

  const handleMyPoliciesClicked = useCallback(async () => {
    await trackEvent('tap_header_mypolicies_cta', {
      ...getCommonEventProperties(pageType),
    }).catch(() => null)
  }, [])

  const handleLogout = useCallback(async () => {
    try {
      await trackEvent('tap_header_logout_cta', {
        ...getCommonEventProperties(pageType),
      }).catch(() => null)

      await logoutUser({
        userId: Cookies.get(KEY_USER_ID),
        refreshToken: Cookies.get(KEY_REFRESH_TOKEN),
        env: config?.ACKO_ENV,
      })
    } catch (error) {
      console.error('Logout failed', error)
    } finally {
      Cookies.remove('refresh_token')
      Cookies.remove('user_id')
      window.location.reload()
    }
  }, [config])

  const avatarRef = useRef<any>()

  const LoginClicked = () => {
    void trackEvent(TAP_HEADER_LOGIN_CTA, {
      ...getCommonEventProperties(pageType),
    })
    window.location.href = loginBaseUrl
  }

  const SignupClicked = () => {
    void trackEvent(TAP_BTN_SIGNUPNOW, {
      ...getCommonEventProperties(pageType),
    })
    window.location.href = SIGNUP_PATH
  }

  useEffect(() => {
    const rootElement = document.getElementById('__next')
    const bodyElement = document.body

    if (!isDesktop()) {
      if (isHeaderVisible) {
        rootElement && (rootElement.style['overflow-y'] = 'hidden')
        bodyElement && (bodyElement.style['overflow-y'] = 'hidden')
      } else {
        rootElement && (rootElement.style['overflow-y'] = 'scroll')
        bodyElement && (bodyElement.style['overflow-y'] = 'scroll')
      }
    }
  }, [isHeaderVisible])

  return (
    <>
      <header
        onMouseLeave={() => {
          setCurrentlyActiveSection(-1)
        }}
        id="global-header"
        className={`${styles.header} ${isHeaderVisible ? styles.isHeaderVisible : ''} ${
          isShowingLinks ? styles.showingLinks : ''
        } ${currentlyActiveSection !== -1 ? styles.activeSection : ''} ${darkMobile ? styles.darkMobile : ''} ${
          largeHeader ? styles.largeHeader : ''
        } ${currentlyActiveSection === -1 ? styles[theme] : ''}
        `}>
        <div
          className={`${styles.headerMainWrapper} ${isHeaderVisible ? styles.isHeaderVisible : ''} ${
            isLoggedIn ? styles.isLoggedIn : ''
          } ${darkDesktop ? styles.darkDesktop : ''} ${largeHeader ? styles.largeHeader : ''} ${
            !schema?.showLoginStatus ? styles.onlyFaqSection : ''
          } ${currentlyActiveSection === -1 ? styles[theme] : ''} ${isHomeWrapper ? styles.isHomeWrapper : ''}`}>
          <div
            className={`${styles.iconWrapper} ${isHeaderVisible ? styles.isHeaderVisible : ''} ${
              largeHeader ? styles.largeHeader : ''
            } ${darkDesktop ? styles.darkDesktop : ''} ${darkMobile ? styles.darkMobile : ''} ${
              currentlyActiveSection === -1 && !isHeaderVisible ? styles[theme] : ''
            }`}>
            <button
              type="button"
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                if (!isShowingLinks || isDesktop()) {
                  window.location.href = '/?redirect=false'
                } else {
                  setCurrentlySelectedType({
                    type: '',
                    typeIndex: -1,
                  })
                  setCurrentlyActiveSection(-1)
                  setIsShowingLinks(false)
                }
              }}
              style={{
                textDecoration: 'none',
                background: 'none',
                border: 'none',
                padding: 0,
                cursor: 'pointer',
              }}
              aria-label={isShowingLinks ? 'Back' : 'Home'}>
              {isShowingLinks && !isDesktop() ? (
                darkMobile ? (
                  <BackComponent white />
                ) : (
                  <BackComponent white={false} />
                )
              ) : (
                <Image
                  width={75}
                  height={24}
                  src={
                    isHomeWrapper && currentlyActiveSection === -1 && !isHeaderVisible
                      ? `${assetLoader()}/images/acko-logo-dark-theme.svg`
                      : schema?.ackoCompanyLogo
                  }
                  alt="Acko Company Logo"
                  priority
                />
              )}
            </button>

            <HeaderButton
              onClose={() => {
                setCurrentlyActiveSection(-1)
                // activateSection({section:-1})
                setCurrentlySelectedType({
                  type: '',
                  typeIndex: -1,
                })
                setIsShowingLinks(false)
              }}
              isHeaderVisible={isHeaderVisible}
              setIsHeaderVisible={setIsHeaderVisible}
              darkMobile={darkMobile || (theme === HEADER_THEME.DARK && !isHeaderVisible)}
              theme={theme}
              pageType={pageType}
              isHamburgerVisible={schema?.categories?.length > 0}
              faqLink={schema?.faqLink}
              showLoginStatus={schema?.showLoginStatus}
              faqIcon={schema?.faqIcon}
            />
          </div>

          <Categories
            setIsShowingLinks={setIsShowingLinks}
            isShowingLinks={isShowingLinks}
            currentlySelectedType={currentlySelectedType}
            setCurrentlySelectedType={setCurrentlySelectedType}
            schema={schema}
            currentlyActiveSection={currentlyActiveSection}
            setCurrentlyActiveSection={setCurrentlyActiveSection}
            isHeaderVisible={isHeaderVisible}
            isLoggedIn={isLoggedIn}
            isDesktop={isDesktop()}
            pageType={pageType}
            largeHeader
            darkDesktop={darkDesktop}
            theme={theme}
            isHomeWrapper={isHomeWrapper}
            setShowProfileModal={setShowProfileModal}>
            {isLoggedIn && isHeaderVisible && (
              <div className={`${styles.mobileOnly}`}>
                <ProfileModal
                  avatarClick={AvatarClick}
                  userData={userData}
                  showProfileModal={showProfileModal}
                  avatarRef={avatarRef}
                  largeHeader={largeHeader}
                  isHeaderVisible={isHeaderVisible}
                  handleLogout={handleLogout}
                  handleMyPoliciesClicked={handleMyPoliciesClicked}
                />
              </div>
            )}
          </Categories>

          {schema?.showLoginStatus &&
            // <>
            (isLoggedIn ? (
              <>
                <div
                  className={`${styles.modalWrapper} ${darkDesktop ? styles.darkDesktop : ''} ${
                    showProfileModal ? styles.active : ''
                  } ${currentlyActiveSection === -1 ? styles[theme] : ''}`}
                  onClick={AvatarClick}>
                  <span className={`${styles.StyledImageContainer}`}>
                    {darkDesktop ? (
                      <Image src={largeIcons.avatarWhiteBackground} alt="avatar white" width={30} height={30} />
                    ) : (
                      <Image src={largeIcons.avatarGreyBackground} alt="avatar grey" width={30} height={30} />
                    )}
                  </span>
                  <span>My account</span>
                  <ToggleIcon
                    isPurple={darkDesktop || (theme !== HEADER_THEME.LIGHT && currentlyActiveSection === -1)}
                    isActive={showProfileModal}
                  />
                </div>
                <ProfileModal
                  avatarClick={AvatarClick}
                  userData={userData}
                  showProfileModal={showProfileModal}
                  avatarRef={avatarRef}
                  largeHeader={largeHeader}
                  handleMyPoliciesClicked={handleMyPoliciesClicked}
                  handleLogout={handleLogout}
                />
              </>
            ) : isLoggedIn !== undefined ? (
              <div className={`${styles.buttonSection} ${schema?.faqLink?.length > 0 ? styles.hasFaq : ''}`}>
                <div className={styles.buttonWrapper}>
                  <Button name="Login" onClick={() => LoginClicked()} />
                </div>
                <div className={styles.buttonWrapper}>
                  <Button isPrimary name="Sign Up" onClick={() => SignupClicked()} />
                </div>
              </div>
            ) : null)}
          <FAQButton
            faqLink={schema?.faqLink}
            showLoginStatus={schema?.showLoginStatus}
            faqIcon={schema?.faqIcon}
            isMobile={false}
          />
        </div>
      </header>
      <div className={`${styles.relativeHeader} ${currentlyActiveSection !== -1 ? styles.visible : ''}`} />
    </>
  )
}

export default GlobalHeader
