import React from 'react'

interface IconProps {
  stroke: string
}

export const icons = {
  chevronUp: ({ stroke }: IconProps) => (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke={stroke}
        d="M8.30482 1.72077L4.98651 5.55696L1.3999 1.97035"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  chevronDown: ({ stroke }: IconProps) => (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke={stroke}
        d="M8.30482 1.72077L4.98651 5.55696L1.3999 1.97035"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  leftChevron: ({ stroke }: IconProps) => (
    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.86204 12.1935L1.99993 7.12275L7.48066 1.64202"
        stroke={stroke || '#1B73E8'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  plusIcon: 'https://acko-seo-prod.ackoassets.com/_next_static/images/plus-icon-global-header.svg',
  minusIcon: 'https://acko-seo-prod.ackoassets.com/_next_static/images/minus-icon-global-header.svg',
  myPolicies: 'https://acko-seo-prod.ackoassets.com/_next_static/images/mypolicies-global-header-icon.svg',
  logoutIcon: 'https://acko-seo-prod.ackoassets.com/_next_static/images/logout-icon-global-header.svg',
}
