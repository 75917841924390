import { getConfig } from '@configs/evnConfig'

export const generateLoginUrl = currentEnv => {
  const envConfig = getConfig(currentEnv)
  return `${envConfig.ackoBaseUrl}/authn/v1/signin`
}

export const generateLogoutUrl = currentEnv => {
  const envConfig = getConfig(currentEnv)
  return `${envConfig.ackoBaseUrl}/platform/api/realms/${envConfig?.loginConfig?.imsRealm}/protocol/openid-connect/logout`
}
