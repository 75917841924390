/* eslint-disable arrow-body-style */

'use client'

import React from 'react'

const AnchorLink = ({ item }) => {
  return (
    <a
      href={item?.url}
      onClick={e => {
        e.preventDefault()
        window.open(item?.url, '_blank')
      }}>
      {item?.name}
    </a>
  )
}

export default AnchorLink
