import Image from 'next/image'
import styles from './globalHeader.module.scss'
import { icons } from './icons'

export const ToggleIcon = ({ isActive = false, isPurple }) => (
  <>
    <div className={`${styles.hideOnMobile} ${isActive ? styles.ghumaDe : ''}`}>
      {/* <ChevronDown stroke={isPurple ? 'white' : '#040222'} /> */}
      {icons.chevronDown({ stroke: isPurple ? 'white' : '#040222' })}
    </div>
    <span className={styles.hideOnDesktop}>
      {isActive ? (
        <Image src={icons.minusIcon} alt="Minus icon" width={16} height={3} />
      ) : (
        <Image src={isActive ? icons.minusIcon : icons.plusIcon} alt="Plus icon" width={16} height={16} />
      )}
    </span>
  </>
)
